/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { show, showMessages, update } from '@intercom/messenger-js-sdk';
import { AuthService } from '@vaki/shared/auth/data-access';
import { API_URL, INTERCOM_APP_ID } from '@vaki/shared/util/environment';
import { filter, switchMap } from 'rxjs';

interface IntercomUser {
  email: string;
  name: string;
  whatsapp: string;
  avatar: string;
}
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly apiVakiUrl = inject(API_URL);
  private readonly intercomAppId = inject(INTERCOM_APP_ID);
  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);

  isLoaded = signal(false);
  userHash = signal<string | undefined>(undefined);
  intercomUser = signal<IntercomUser | undefined>(undefined);

  user$ = toObservable(this.authService.firebaseUser);
  dbUser$ = toObservable(this.authService.dbUser);

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.user$
        .pipe(
          takeUntilDestroyed(),
          filter((user) => !!user && !user.isAnonymous),
          switchMap((user) =>
            this.http.get<{ hash: string }>(
              `${this.apiVakiUrl}/intercom/hashUser?userId=${user?.uid}`
            )
          )
        )
        .subscribe(({ hash }) => {
          this.userHash.set(hash);
          this.updateIntercomUser();
        });

      this.dbUser$.pipe(takeUntilDestroyed()).subscribe((user) => {
        if (user?.email) {
          this.updateIntercomUser();
        }
      });
    }
  }

  loadIntercom() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isLoaded()) return;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${this.intercomAppId}`;
      script.onload = () => {
        (window as any).Intercom =
          (window as any).Intercom ||
          function (...args: any[]) {
            (window as any).Intercom.q = (window as any).Intercom.q || [];
            (window as any).Intercom.q.push(args);
          };
        // Initialize Intercom
        (window as any).Intercom('boot', {
          app_id: this.intercomAppId
        });
      };
      document.head.appendChild(script);
      this.isLoaded.set(true);
    }
  }

  showIntercom() {
    show();
  }

  updateIntercomUser() {
    const intercomUser = {
      userId: this.authService.uid(),
      email: this.authService.userEmail(),
      name: this.authService.userName(),
      whatsapp: this.authService.userPhone(),
      avatar: this.authService.userPhoto()
    };
    if (
      isPlatformBrowser(this.platformId) &&
      this.isLoaded() &&
      this.userHash()
    ) {
      this.http
        .post<IntercomUser>(
          `${this.apiVakiUrl}/intercom/update-user`,
          intercomUser
        )
        .subscribe((resp) => this.intercomUser.set(resp));
    }
  }

  newMessage() {
    if (isPlatformBrowser(this.platformId)) {
      showMessages();
    }
  }

  hideMessenger() {
    if (isPlatformBrowser(this.platformId)) {
      update({
        hide_default_launcher: true
      });
    }
  }

  updateMessanger() {
    if (isPlatformBrowser(this.platformId)) {
      update({
        hide_default_launcher: false
      });
    }
  }
}
